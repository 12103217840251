<template>
  <Form class="py-14" @submit="handleSubmit">
    <template v-if="credentialToLinkOnLogin">
      <h1 class="font-bold text-center text-3xl">Sign In</h1>
      <p class="text-center font-semibold">to continue</p>
    </template>
    <h1 v-else class="font-bold text-center text-3xl">Finish signing in</h1>

    <alert v-if="credentialToLinkOnLogin" class="mt-4" :type="AlertType.INFO">
      It looks like you already have an account with Exfluential with another
      organization, please login below to link them.
    </alert>

    <div class="mt-10">
      <TextInput
        v-if="credentialToLinkOnLogin"
        v-model="email"
        label="Email"
        placeholder="Email"
        :rules="[isRequired, isEmail]"
        @update:model-value="errorMessage = ''"
      />
      <div
        v-else
        class="w-full border border-on-surface-variant rounded-full min-h-10 flex items-center justify-center gap-2 text-on-surface-variant"
      >
        <Icon :icon="ProfileSvg" />
        {{ email }}
      </div>
      <PasswordInput
        v-model="password"
        label="Enter Password"
        placeholder="Password"
        :rules="[isRequired]"
        @update:model-value="errorMessage = ''"
      />
    </div>

    <div class="text-right text-primary text-sm font-bold">
      <NuxtLink to="reset-password"> Forgot your password? </NuxtLink>
    </div>

    <alert v-if="errorMessage" class="mt-4" :type="AlertType.ERROR">
      {{ errorMessage }}
    </alert>

    <Btn
      :size="BtnSize.LG"
      :variant="BtnVariant.FILLED"
      :color="BtnColor.PRIMARY"
      class="w-full mt-10"
      type="submit"
    >
      Sign in
    </Btn>
  </Form>
</template>

<script setup lang="ts">
import { navigateTo, ref, useRoute } from '#imports';
import { Form } from 'vee-validate';
import { FirebaseError } from '@firebase/app';
import { Btn, BtnSize, BtnColor, BtnVariant } from '~/components/btn';
import { isRequired } from '~/utils/validators';
import { useLoader } from '~/composables/use-loader';
import { PasswordInput } from '~/components/inputs/password-input';
import { Icon } from '~/components/icon';
import ProfileSvg from '~/assets/icons/profile.svg?component';
import { useFirebase } from '~/composables/use-firebase';
import { useAuthUserStore } from '~/store/auth-user';
import { signInWithEmailAndPassword } from '@firebase/auth';
import {
  apiGetLoggedInUserInfo,
  apiLogin,
  apiResendInvitation,
  SelfRegisterType,
} from '~/api/auth';
import { getFirstQueryParam } from '~/utils/common';
import { Alert, AlertType } from '~/components/alert';
import { AuthErrorCodes, linkWithCredential } from 'firebase/auth';
import { useOrganizationStore } from '~/store/organization';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '~/store/account';
import { TextInput } from '~/components/inputs/text-input';

const route = useRoute();
const password = ref('');
const authUserStore = useAuthUserStore();
const accountStore = useAccountStore();
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const { token, credentialToLinkOnLogin } = storeToRefs(authUserStore);

const errorMessage = ref('');
const email = defineModel<string>({ required: true });

const emit = defineEmits<{
  submit: [];
  'change-type': [value: SelfRegisterType];
}>();

const getErrorMessage = (err: unknown): string => {
  if (
    err instanceof FirebaseError &&
    err.code === AuthErrorCodes.INVALID_PASSWORD
  ) {
    return 'The password entered is incorrect';
  }
  return 'Auth failed';
};

const handleSubmit = async () => {
  const firebase = useFirebase();

  await useLoader({
    action: async () => {
      const { user } = await signInWithEmailAndPassword(
        firebase.auth,
        email.value,
        password.value,
      );
      if (!organization.value?.slug) throw new Error('Unknown organization');
      let idToken = '';

      if (user && credentialToLinkOnLogin.value) {
        const linkResult = await linkWithCredential(
          user,
          credentialToLinkOnLogin.value,
        );
        idToken = await linkResult.user.getIdToken();
      } else {
        idToken = await user.getIdToken(true);
      }

      const loginResponse = await apiLogin(idToken, organization.value?.slug);

      token.value = loginResponse.token;
      authUserStore.authUser = await apiGetLoggedInUserInfo();
      await accountStore.setCurrentAccount();

      const redirect = getFirstQueryParam(route.query.redirect);

      emit('submit');

      if (organization.value.isCoOp) {
        navigateTo('/business-selection');
      } else {
        navigateTo(redirect ?? '/');
      }
    },
    onError: async (err: any) => {
      if (err.code === AuthErrorCodes.USER_DISABLED) {
        await apiResendInvitation({ email: email.value });
        emit('change-type', SelfRegisterType.RESEND_CONFIRMATION_INVITE);
      }
      errorMessage.value = getErrorMessage(err);
    },
  });
};
</script>
